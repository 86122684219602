@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
footer,
header,
nav,
video {
  margin: 0;
  padding: 0;
  border: 0;
}
